<template>
    <div id="printArea">
        <CRow class="noprint">
            <CCol lg="12" style="margin-bottom:10px">
                <CButton type="submit" size="sm" class="float-left btn-primary" @click="$router.go(-1)" ><CIcon name="cil-list"/> 목록</CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
               <CCard>
                    <CCardBody >    
                        <CRow>
                            <CCol lg="12">
                                <CRow>
                                    <CCol sm="2">
                                    <strong>앱 이름</strong>
                                    </CCol>
                                    <CCol sm="5">
                                        {{appNm}}
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="2">
                                    <strong>패키지명</strong>
                                    </CCol>
                                    <CCol sm="5">
                                        {{packageNm}}
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="2">
                                    <strong>버전</strong>
                                    </CCol>
                                    <CCol sm="5">
                                        {{appVersion}}
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="2">
                                    <strong>OS</strong>
                                    </CCol>
                                    <CCol sm="5">
                                      <img
                                          :src="$renderer('osType', 2, this.osType)"
                                          width=25
                                          height=25
                                      />
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>   
                    </CCardBody>  
               </CCard>  
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <CCard>           
                    <CCardBody>
                    <List ref="list" 
                        :fields="fields"
                        :items.sync=appPolicyAuditLogList
                        :loading.sync=loading
                        showSearch
                        :comboboxData=comboboxData
                        sorter      
                        :defaultSort=defaultSort
                        showExcelBtn
                        itemsPerPageSelect
                        :itemsPerPage=itemPerPage
                        showDatePicker
                        :defaultSearchDate=searchDate
                        :excel=excel
                        showPrintBtn
                    >    
                    <template #no="{index}">
                        <td>
                          {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
                        </td>
                    </template>  
                    </List>
                    <page-link ref="page"
                        :pageLinkCount=pagePerLink
                        :listRowCount=itemPerPage
                    />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>


<script>
import List from "@/views/common/List.vue";
import PageLink from "@/views/common/PageLink.vue";
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory} from '@coreui/icons'
// COREUI 아이콘 목록 : https://icons.coreui.io/icons/
export default {
    name: 'appPolicyAuditLogList',
    icons: { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory},
    components: {
        List,
        PageLink,
        CIcon
    },
    computed: {
      // 목록에 들어갈 데이터
      appPolicyAuditLogList(){ 
        return this.$store.state.appInfo.appPolicyAuditLogList || []
      },
      searchDate(){
          var beforeDay = new Date()
          beforeDay.setDate(beforeDay.getDate()-365)
          return [beforeDay, new Date()]
      },
      appKey () {
        return this.$store.state.dataStore.appPolicyAuditLogList_appKey 
      },      
      totalCnt(){
        return this.$store.state.appInfo.appPolicyAuditLogListTotalCnt
      }
  },
  // 로컬변수생성
  data() { 
		return{
      searchType:"regId", // 콤보박스 디폴트 값(하기 comboboxData의 value로 설정)
      searchText:"",
      // 검색조건 설정
      comboboxData:[{label: '행위', value: 'appPolicyAuditEvent',codeYn: true}
                    ,{label: '관리자', value:'regId'}],
      // 그리드 컬럼 설정
      fields:[
          { key: 'no', label:'No', _style: { width: '2%'} },
          { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
          { key: 'appPolicyAuditEvent', label:'행위', _style: { width: '10%'}, badgeYn: true, codeYn: true },
          { key: 'appTamperDetectYn', label:'APP', sorter: false, _style: { width: '10%'}, codeYn:true, code:'detectYn', badgeYn:true },
          { key: 'osTamperDetectYn', label:'OS', sorter: false, _style: { width: '10%'}, codeYn:true, code:'detectYn', badgeYn:true},
          { key: 'debugDetectYn', label:'Debug', sorter: false, _style: { width: '10%'}, codeYn:true, code:'detectYn', badgeYn:true },
          { key: 'regDate', label:'변경일', _style: { width: '10%'} },
          { key: 'regId', label:'관리자', _style: { width: '10%'} },
      ],
      pagePerLink: 5, // 한번에 보여줄 페이지번호 수
      defaultSort: "-regDate", // 기본 정렬 설정.
      pageNum: 1, // 페이지번호
      itemPerPage:5,  // 한페이지당 보여줄 데이터수
      loading:false,
      checkedList:[], // 목록 데이터에서 체크된 데이터 리스트
      alert:{ 
        noItem:"선택된 아이템이 없습니다.",
      },
      isOpenModifyAppModal: false,
      excel: {
        excelType : '3',  // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록)
        excelFileNm : "앱 정책 감사로그",
        excelParams: {
          appKey: this.$store.state.dataStore.appPolicyAuditLogList_appKey
        }
      },
      appNm:'',
      packageNm:'',
      appVersion:'',
      osType:'',
    }
  },
  // 파라미터
  props: {
    caption: {
      default: '앱 정책 감사 로그'
    },
  },
  mounted(){
    this.selectAppInfo()
    this.searchList()
  },
  methods: {
    // 리스트조회
    async searchList(){
      let payload = this.$refs.list.getParams()
      payload.appKey = this.appKey
      this.loading=true 
      try {
        await this.$store.dispatch('appInfo/getAppPolicyAuditLogList', payload)
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }
    },

    async selectAppInfo() {            
        this.loading=true 
        try {
            await this.$store.dispatch('appInfo/getAppInfo', this.appKey)
            let appInfo = this.$store.state.appInfo.appInfo
            // 앱정보 셋팅
            this.appNm = appInfo.appNm
            this.packageNm = appInfo.packageNm
            this.appVersion = appInfo.appVersion
            this.osType = appInfo.osType
            this.loading=false
        } catch(error){
            this.$response(error)
            this.loading=false
        }

        return true
    },
  }
}
</script>